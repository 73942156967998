<!-- 群列变H5  用户在微信上使用
http://dashboard.scrm.accessible.mobi/roomFission/index -->

<template>
    <div>
        <!--   用户页面-->
        <div class="page" v-show="type == 2">

            <div>
                启明店邀请好友进群活动开始了！这次活动很简单，只要邀请人8人进群即可获得一个价值19.9元的语音寻物器。注意，邀请的需要是盲人朋友哦！同时邀请的人不能退群哦！退群不算！
                活动时间从9月7号到9月9号哦！
                邀请方式：点击链接右上角找到更多按钮。将此页面分享给朋友或者朋友圈，好友点击链接进去后，识别页面下面的群二维码图片即可进群，邀请8人即可成功获得语音寻物器。
            </div>
            <div class="top">
                <div class="code-image">
                    <div class="code-border">
                        <div class="show_poster" id="showPoster">
                            <img alt="" class="cover_pic" id="coverPic">
                            <div class="show_user_news">
                                <div class="show_user_data">
                                    <img :src="wxUserData.headimgurl" alt="" class="user_img">
                                    <div class="user_name" :style="{color:posterNews.nicknameColor}">{{
                                    wxUserData.nickname }}
                                    </div>
                                </div>
                                <div class="qr-code" ref="qrCode" v-if="imgData==''"></div>
                                <img v-if="imgData!==''" :src="imgData" class="qr-code">
                            </div>
                        </div>
                        <!-- <img v-if="posterImg!==''" :src="posterImg" class="show_poster"> -->
                    </div>
                </div>
            </div>
            <div class="bottom-box">
                <div class="long-btn" @click="">
                    点击此按钮分享给视障朋友，同时识别上面邀请朋友获得奖励
                </div>
            </div>
            <div class="bottom-box">
                <div class="long-btn"
                    @click="$router.push('/fissionSpeed?fission_id=' + fission_id + '&wxUserData=' + JSON.stringify(wxUserData))">
                    查看助力进度
                </div>
            </div>
            <input type="text" ref="copyInput" style="position: fixed; opacity: 0">
        </div>
        <!--      群聊页面-->
        <div class="pageCode" v-if="type == 1">
            <div class="shop_guide">启明店邀请好友进群活动开始了！这次活动很简单，只要邀请人8人进群即可获得一个价值19.9元的语音寻物器。注意，邀请的需要是盲人朋友哦！同时邀请的人不能退群哦！退群不算！
                活动时间从9月7号到9月9号哦！
                邀请方式：点击链接右上角找到更多按钮。将此页面分享给朋友或者朋友圈，好友点击链接进去后，识别页面下面的群二维码图片即可进群，邀请8人即可成功获得语音寻物器。</div>
            <div class="qc_code">
                <div class="code-box">
                    <div class="top">
                        <div class="right">
                            <div class="shop_name">{{ roomNews.name }}</div>
                        </div>
                    </div>
                    <div class="bottom">
                        <div class="code">
                            <img :src="roomNews.roomQrcode" alt="" class="qr_room_img" />
                        </div>
                    </div>
                    <!-- <div class="shop_guide">长按上面图片识别图中二维码加入群聊</div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import html2canvas from 'html2canvas'
import { openUserInfoApi, posterApi } from '@/api/roomFission'

export default {
    name: "index",
    data() {
        return {
            url: window.location.href,
            type: 0,
            dataList: {},
            userInfo: {},
            bgHeight: 'auto',
            base64Img: '',
            //    微信授权信息
            wxUserData: {},
            //  群聊信息
            roomNews: {},
            //  用户海报信息
            posterNews: {},
            imgData: '',
            timer: null,
            posterImg: '', // 最终生成的海报图片

        }
    },

    created() {
        let url = window.location.href.split('#')[0];

        if (navigator.userAgent.indexOf('iPhone') !== -1) {
            window.wechaturl = window.location + '';
        }
        if (window.wechaturl !== undefined) {
            url = window.wechaturl;
        }
        this.url = url;
        this.fission_id = this.$route.query.id;
        this.parentUnionId = this.$route.query.parent_union_id;
        this.wxUserId = this.$route.query.wx_user_id;
        this.getOpenUserInfo();
    },
    methods: {
        getOpenUserInfo() {
            let that = this;
            openUserInfoApi({
                id: that.fission_id
            }).then((res) => {
                if (res.data.openid === undefined) {
                    let redirectUrl = '/auth/roomFission?id=' + that.fission_id + '&target=' + encodeURIComponent(that.url);
                    that.$redirectAuth(redirectUrl);
                }

                this.wxUserData = res.data;
                this.getPosterData();

            });
        },
        //  获取海报数据
        getPosterData() {
            var that = this;
            let params = {
                fission_id: that.fission_id,
                parentUnionId: that.parentUnionId,
                wxUserId: that.wxUserId,
                union_id: that.wxUserData.unionid,
                nickname: that.wxUserData.nickname,
                avatar: that.wxUserData.headimgurl,
            }
            posterApi(params).then((res) => {
                document.title = res.data.poster.activeName;
                if (res.data.type == 0) {
                    that.$message.info('活动已结束');
                    return false
                }
                if (res.data.type == 1) {
                    that.roomNews = res.data.room
                }
                if (res.data.type == 2) {
                    that.posterNews = res.data.poster
                    that.convertImgToBase64(that.posterNews.coverPic, function (base64Img) {
                        //转化后的base64
                        var backImg = document.getElementById('coverPic')
                        backImg.setAttribute('src', base64Img)
                    });
                    clearTimeout(that.timer);
                    that.timer = setTimeout(() => {
                        that.initQrcode()
                    }, 1000);
                }
                that.type = res.data.type
            })
        },
        initQrcode() {
            var that = this;
            that.$refs.qrCode.innerHTML = ''
            new QRCode(that.$refs.qrCode, {
                text: that.posterNews.qrCodeUrl,
                width: 200,
                height: 200
            });
            clearTimeout(that.timer);
            that.timer = setTimeout(() => {
                Promise.all([
                    that.createPicture(), // 二维码生成后，执行生成图片
                ]).then(res => {
                    that.createPoster();
                })
            }, 1000);

        },

        createPicture() {
            html2canvas(this.$refs.qrCode, {
                backgroundColor: null,
                width: 200,
                height: 200,
                useCORS: true,
            }).then(canvas => {
                var imgData = canvas.toDataURL('image/jpeg')
                this.imgData = imgData
            })
        },

        createPoster() {
            // 生成海报
            const that = this
            const domObj = document.getElementById('showPoster')
            html2canvas(domObj, {
                useCORS: true,
                allowTaint: false,
                logging: false,
                letterRendering: true,
                onclone(doc) {
                    let e = doc.getElementById('showPoster')
                    e.style.display = 'block'
                }
            }).then(function (canvas) {
                // 在微信里,可长按保存或转发

                that.posterImg = canvas.toDataURL('image/png')
                console.log(that.posterImg)
            })
        },

        //实现将项目的图片转化成base64
        convertImgToBase64(url, callback, outputFormat) {
            var canvas = document.createElement('CANVAS'),
                ctx = canvas.getContext('2d'),
                img = new Image;
            img.crossOrigin = 'Anonymous';
            img.onload = function () {
                canvas.height = img.height;
                canvas.width = img.width;
                ctx.drawImage(img, 0, 0);
                var dataURL = canvas.toDataURL(outputFormat || 'image/png');
                callback.call(this, dataURL);
                canvas = null;
            };
            img.src = url;
        }
    }
}
</script>

<style scoped lang="scss">
.page {
    width: 100vw;
    height: 100vh;
    background-color: #ff5636;
    padding: 0 20px 80px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-image: url("../../static/images/bg.png");
    background-size: cover;
    overflow-y: auto;

    .top {
        .reply {
            padding-top: 16px;

            .content {
                padding: 16px 20px;
                border-radius: 16px;
                background-color: rgb(253, 253, 229);

                .title {
                    display: flex;
                    justify-content: space-between;

                    .tip {
                        color: saddlebrown;
                        font-size: 15px;
                        position: relative;

                        &:before {
                            content: '';
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            height: 7px;
                            background-color: #b97e1229;
                        }
                    }
                }
            }
        }

        .tips {
            padding: 20px 0;

            .tip {
                display: flex;
                justify-content: center;
                margin-bottom: 4px;

                .text {
                    position: relative;
                    color: white;
                    z-index: 0;

                    &::before {
                        $width: 90%;
                        position: absolute;
                        content: '';
                        bottom: 0;
                        z-index: -1;

                        left: ((100% - $width) / 2);

                        height: 10px;
                        width: $width;
                        border-radius: 100px;
                        background-color: rgba(255, 255, 255, 0.15);
                    }
                }

                .long-text {
                    &::before {
                        $width: 110%;
                        left: ((100% - $width) / 2);
                        width: $width;
                    }
                }
            }
        }

        .code-image {
            width: 100%;
            background-color: navajowhite;
            border-radius: 10px;
            box-shadow: inset 0 4px 6px 0 #0000001c;
            border: 2px solid white;
            padding: 10px;
            margin-top: 30px;

            .code-border {
                width: 100%;
                padding: 16px;
                background-color: white;
                border-radius: 12px;
            }
        }
    }

    .bottom-box {
        width: 100vw;
        position: fixed;
        bottom: 0;
        left: 0;
        padding: 10px 0 15px;
        box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.19);
        background-color: #ff5636;
        display: flex;
        justify-content: center;

        .long-btn {
            width: 90%;
            height: 42px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: darkorange;
            font-size: 16px;
            border: 2px solid #ffd6a1;
            border-radius: 100px;
            background-color: #ffecdb;
        }
    }


}

.show_poster {
    width: 100%;
    height: 75vh;
    position: relative;
    //background: skyblue;
}

.cover_pic {
    width: 100%;
    height: 100%;
}

.show_user_news {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
}

.show_user_data {
    margin-left: 15px;
    margin-top: 15px;
    display: flex;
}

.qr-code {
    position: absolute;
    width: 150px;
    height: 150px;
    bottom: 10px;
    right: 10px;
}

.user_img {
    width: 35px;
    height: 35px;
    border-radius: 5px;
}

.user_name {
    margin-left: 10px;
    margin-top: 6px;
    font-size: 15px;
    font-weight: bold;
}

.user_qrCode {
    width: 50px;
    width: 50px;
}

//abc
.pageCode {
    width: 100vw;
    height: 100vh;
    background-color: #bfddff;
}

.qr_room_img {
    width: 257px;
    height: auto;
}

.qc_code {
    display: flex;
    justify-content: center;

    .code-box {
        margin-top: 50px;
        width: 90vw;
        height: auto;
        background-color: #ffffff;
        padding-bottom: 20px;
    }

    .top {
        display: flex;
        align-items: center;
        padding: 28px 28px 20px 28px;

        .left {
            margin-right: 15px;
        }
    }

    .shop_name {
        font-size: 17px;
        color: #222;
        font-weight: bold;
    }

    .shop_describe {
        color: #818181;
    }

    .shop_guide {
        margin-top: 17px;
        text-align: center;
        color: #818181;
    }
}
</style>
