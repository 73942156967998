import {request_op, request} from "../plugins/axios";

//获取微信用户信息
export function getUserInfoApi (params) {
    return request_op({
        url: '/openUserInfo/setDataToSheet',
        method: 'GET',
        params: params
    })
}

export function setDataToSheetApi (params) {
    return request({
        url: '/workFission/setDataToSheet',
        method: 'GET',
        params: params
    })
}