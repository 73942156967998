<template>
    <div class="page">
        <div class="content">活动内容：活动期间下载体验微众银行APP无障碍版本，满足新开户条件即可获得100元现金红包或者启明店商城满150元减120元代金券一张！二选一哦！</div>
        <br>
        <div class="content">邀请奖励：活动期间同时邀请身边的视障朋友注册开户我们有单独的10元的邀请红包奖励哦。</div>
        <br>
        <div class="content">请登记注册微众银行时的手机号（领奖励用）：</div>
        <input type="number" v-model="mobile" placeholder="请输入用户手机号：">
        <br>
        <div class="btn" @click="setMobile(mobile)">登记手机号</div>
        <br>
        <div class="content">各大手机应用商城都可以搜索“微众银行”并下载，下载完成后，在读屏开启的状态下打开App并按照指引进行登录和开户，也可以点击下面的按钮复制下载微众银行链接进入浏览器进行下载呢！</div>
        <br>
        <div class="btn" @click="copyText('https://dbd.webankcdn.net/wm-resm/hj/pkg/android/channel/cdn/webank_android_release_wza-qiming.apk')">复制微众银行下载链接</div>
        <br>
        <div class="content">开户完成后的1到3个工作日内，微众银行工作人员会联系您进行奖励发放。</div>
        <br>
        <div class="content">如对参与流程有任何疑问之处，可联系微众工作人员了解：</div>
        <br>
        <div class="content">QQ号：241156875</div>
        <br>
        <div class="content">微信号：449204068</div>
        <br>
        <div class="content">添加时请备注【启明】</div>
        <br>
        <div class="content">下面是工作人员企业二维码</div>
        <br>
        <img src="../../../image/kefu.png" height="180" width="180" alt="" aria-role="button" aria-label="长按扫码添加企业微信客服" />
        <input type="text" ref="copyInput" style="position: fixed; opacity: 0">
    </div>
    
</template>

<script>
import { getUserInfoApi, setDataToSheetApi } from "@/api/setDataToSheet";

export default {
    name: "index",
    data() {
        return {
            url: window.location.href,
            userInfo: {},
            mobile: '',
            nowTime: '',
            unionid: ''
        }
    },
    created() {
        let url = window.location.href.split('#')[0];

        if (navigator.userAgent.indexOf('iPhone') !== -1) {
            window.wechaturl = window.location + '';
        }
        if (window.wechaturl !== undefined) {
            url = window.wechaturl;
        }
        this.url = url;
        this.unionid = this.$route.query.unionid
    },
    mounted() {
        document.title = "下载微众银行，领取最高120元奖励"
    },
    methods: {
        setMobile(mobile) {
            this.getNowDate();
            const params = {
                unionid: this.unionid,
                mobile: mobile,
                createTime: this.nowTime,
                nickname: null
            }
            setDataToSheetApi(params).then((res) => {
                console.log(res)
                this.$message.success('登记手机号成功，请勿重复提交')
            });
        },

        getNowDate() {
            var date = new Date();
            var sign2 = ":";
            var year = date.getFullYear() // 年
            var month = date.getMonth() + 1; // 月
            var day = date.getDate(); // 日
            var hour = date.getHours(); // 时
            var minutes = date.getMinutes(); // 分
            var seconds = date.getSeconds() //秒
            // 给一位数的数据前面加 “0”
            if (month >= 1 && month <= 9) {
                month = "0" + month;
            }
            if (day >= 0 && day <= 9) {
                day = "0" + day;
            }
            if (hour >= 0 && hour <= 9) {
                hour = "0" + hour;
            }
            if (minutes >= 0 && minutes <= 9) {
                minutes = "0" + minutes;
            }
            if (seconds >= 0 && seconds <= 9) {
                seconds = "0" + seconds;
            }
            this.nowTime = year + "-" + month + "-" + day + " " + hour + sign2 + minutes + sign2 + seconds;
        },
        copyText(text) {
            const inputElement = this.$refs.copyInput;
            inputElement.value = text;
            inputElement.select();
            document.execCommand('Copy');
            this.$message.success('复制成功')
        }
        // downloadAndroid() {
        //     var src = 'https://sj.qq.com/appdetail/com.webank.wemoney';
        //     var iframe = document.createElement('iframe');
        //     iframe.style.display = 'none';
        //     iframe.src = "javascript: '<script>location.href=\"" + src + "\"<\/script>'";
        //     document.getElementsByTagName('body')[0].appendChild(iframe);
        // }
    }
}
</script>

<style scoped lang="scss">
.page {
    width: 100vw;
    height: 100vh;
    background-color: #050505;
    padding: 0 20px 80px;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    background-size: cover;
    overflow-y: auto;

    input {
        width: 95%;
        height: 48px;
        border: 1px solid rgba(255, 255, 255, .4);
        border-radius: 4px;
        display: block;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-size: 18px;
        color: #fff;
        padding-left: 45px;
        padding-right: 20px;
        margin: auto;
        background: rgba(255, 255, 255, 0.4) no-repeat 16px 16px;
    }

    ::-webkit-input-placeholder {
        color: #fff;
    }

    input:focus {
        background-color: rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 5px 1px rgba(255, 255, 255, .5);
        overflow: hidden;
    }

    .content {
        font-size: large;
        color: #fff;
        line-height: 10rpx;
    }

    .btn {
        width: 95%;
        height: 44px;
        border-radius: 4px;
        margin: 0 auto;
        background: #00B0DC;
        padding: 10.5px 21px;
        border-radius: 6px;
        color: #e1e1e1;
        text-align: center;
    }
}
</style>
